<template>
  <div id="coach-cutomer" class="food-list-page w-100">
    <Wrapper>
      <FiltersTable
        :loading="loading"
        @select:coach="onSelectCoach"
        @select:group="onSelectGroup"
      />

      <b-row class="mt-0">
        <b-col cols="4">
          <b-tabs card class="customer-list-page__body__tabs">
            <b-tab active @click="onChangeComponent('TableInprogress')">
              <template #title>
                <span>Đang diễn ra</span>
              </template>
            </b-tab>
            <b-tab @click="onChangeComponent('TableFinished')">
              <template #title>
                <span>Đã kết thúc</span>
              </template>
            </b-tab>
          </b-tabs>
        </b-col>
        <b-col v-if="Object.keys(filters.group).length" class="header-comment">
          <b-button
            v-if="
              isTabInprogress &&
              (isWriteAllPermission || isWriteOwnerPermission)
            "
            class="btn btn-success ml-2"
            type="button"
            @click="redirectToZalo(filters.group.linkZalo)"
          >
            <span class="svg-icon">
              <inline-svg
                src="/media/svg/icons/Neolex/Chatting/comment-group.svg"
              />
            </span>
            Nhắn tin nhóm
          </b-button>
          <b-button
            v-if="isWriteAllPermission || isWriteOwnerPermission"
            class="btn btn-success ml-2"
            type="button"
            @click="onShowCommentsModal"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Chatting/comment.svg" />
            </span>
            Nhận xét
          </b-button>
          <b-button
            v-if="
              isTabInprogress &&
              (isWriteAllPermission || isWriteOwnerPermission)
            "
            class="btn btn-success ml-2"
            type="button"
            @click="onShowSurveyModal"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
            </span>
            Thêm mới khảo sát
          </b-button>
        </b-col>
      </b-row>
    </Wrapper>

    <transition
      v-if="
        Object.keys(filters.coach).length && Object.keys(filters.group).length
      "
      name="fade"
    >
      <div v-if="isTabInprogress" class="wrapper-cards">
        <InprogressCards @search="onTabChange" />
      </div>
    </transition>

    <Wrapper>
      <component
        :ref="currentComponent"
        :is="currentComponent"
        :filters="filters"
        :is-inprogress="isTabInprogress"
        :tab-filter="tabFilter"
        @save="getCustomers"
      />
    </Wrapper>

    <CommentsModal
      :groupId="filters.group && filters.group.id"
      @reset="resetCommentModal"
    />
    <SurveyFormModal
      @reset="resetSurveyModal"
      :groupId="filters.group && filters.group.id"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { INPROGRESS_TYPES } from '@/core/plugins/constants';
import {
  GET_OPTIONS,
  GET_OPTIONS_COMPLETED,
  GET_STATISTIAL,
} from '@/core/services/store/groupDashboard.module';
const { mapActions, mapMutations, mapState } = createNamespacedHelpers(
  'groupDashboard',
);

export default {
  name: 'GroupDashboard',

  components: {
    FiltersTable: () => import('./components/FiltersTable.vue'),
    InprogressCards: () => import('./components/InprogressCards.vue'),
    TableInprogress: () => import('./components/TableInprogress.vue'),
    TableFinished: () => import('./components/TableFinished.vue'),

    CommentsModal: () => import('./components/CommentsModal.vue'),
    SurveyFormModal: () => import('./components/SurveyFormModal.vue'),
  },

  data() {
    return {
      // Tabs
      currentComponent: 'TableInprogress',
      currentTab: INPROGRESS_TYPES.TOTAL,

      filters: {
        coach: {},
        group: {},
      },

      loading: false,
    };
  },

  computed: {
    ...mapState(['coaches']),
    currentUser() {
      return this.$store.state.auth.user;
    },

    isTabInprogress() {
      return this.currentComponent === 'TableInprogress';
    },

    tabFilter() {
      return this.currentTab !== INPROGRESS_TYPES.TOTAL
        ? this.currentTab
        : null;
    },

    isWriteAllPermission() {
      return this.$route.meta.isWriteAllPermission;
    },
    isWriteOwnerPermission() {
      return this.$route.meta.isWriteOwnerPermission;
    },
  },

  watch: {
    currentUser: {
      handler() {
        this.initData();
      },
      deep: true,
    },
  },

  mounted() {
    this.initData();
  },

  methods: {
    ...mapActions({ GET_OPTIONS, GET_STATISTIAL }),
    ...mapMutations({ GET_OPTIONS_COMPLETED }),

    initData() {
      if (!Object.keys(this.currentUser).length) return;

      this.filters.coach = {
        id: this.currentUser.id,
        name: this.currentUser.fullName,
      };
      this.getOptions();
    },

    onSelectCoach(coach = {}) {
      this.filters.coach = coach ? { ...coach } : {};
      this.getOptions();
    },

    async getOptions() {
      if (!Object.keys(this.filters.coach).length) {
        return this.GET_OPTIONS_COMPLETED({
          coaches: this.coaches,
          trainingGroups: [],
        });
      }

      this.loading = true;
      const params = {
        coachId: this.filters.coach?.id || null,
        trainingGroupId: this.filters.group?.id || null,
      };
      await this.GET_OPTIONS(params);
      this.loading = false;
    },

    async onSelectGroup(group = {}) {
      this.filters.group = group ? { ...group } : {};
      this.getCustomers();
      Object.keys(this.filters.group).length &&
        this.GET_STATISTIAL({
          trainingGroupId: group?.id,
          coachId: this.filters.coach?.id,
          isPackageExpired: false,
        });
    },

    async onTabChange(tab) {
      this.currentTab = tab;
      this.getCustomers(100);
    },

    async onChangeComponent(component = 'TableInprogress') {
      this.currentComponent = component;
      if (component === 'TableInprogress') {
        this.currentTab = INPROGRESS_TYPES.TOTAL;
        Object.keys(this.filters.group).length &&
          this.GET_STATISTIAL({
            trainingGroupId: this.filters.group?.id,
            coachId: this.filters.coach?.id,
            isPackageExpired: false,
          });
      }
      this.getCustomers(100);
    },

    async getCustomers(time = 0) {
      const fecthData = () => {
        if (this.$refs[this.currentComponent]) {
          this.$refs[this.currentComponent].reFecthCustomers();
        }
      };
      if (!time) return fecthData();

      // Wait for refs have been updated
      const timer = setTimeout(() => {
        fecthData();
        clearTimeout(timer);
      }, time);
    },

    showToastrMessage(message, type) {
      if (type === 'error') {
        return this.$toastr.e({
          title: 'Lỗi',
          msg: message,
        });
      }

      return this.$toastr.s({
        title: 'Thành công',
        msg: message,
      });
    },

    onShowCommentsModal() {
      this.$bvModal.show('modal-comments-list');
    },

    onShowSurveyModal() {
      this.$bvModal.show('modal-survey-form');
    },

    resetCommentModal() {
      this.showCommentModal = false;
    },

    resetSurveyModal() {
      this.showSurveyModal = false;
    },

    redirectToZalo(url) {
      if (!url) return;
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
#coach-cutomer::v-deep {
  .card-header {
    border-bottom: none;
  }

  .container-fluid {
    margin-bottom: 0 !important;
  }
}

.tab-active {
  background-color: #fff !important;
  border: 1px solid #01645a;
}

.header-comment {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 0;
}

.wrap-icon {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-content: center;
  background: #e4f5f5;
  border-radius: 8px;
}

.wrap-icon-calender {
  background: #e6f6ed;
}
.wrap-icon-card {
  background: #fff7c0;
}
.wrap-icon-group {
  background: #feeddc;
}

.wrap-title {
  h5 {
    text-align: left;
  }

  div {
    color: #000;
    text-align: left;
  }
}

.wrapper-cards {
  padding: 0 15px;

  @media (min-width: 992px) {
    padding: 0 25px;
  }
}

.customer-list-page__body__tabs::v-deep {
  margin: 0 -2rem -2rem;

  a.nav-link {
    border: 0;

    &.active {
      font-weight: 600;
      color: #01645a !important;
      border-bottom: 2px solid #01645a;
    }
  }

  li.nav-item {
    a.nav-link:hover {
      color: #333;
    }
  }

  .tab-content {
    display: none;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
