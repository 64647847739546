var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"food-list-page w-100",attrs:{"id":"coach-cutomer"}},[_c('Wrapper',[_c('FiltersTable',{attrs:{"loading":_vm.loading},on:{"select:coach":_vm.onSelectCoach,"select:group":_vm.onSelectGroup}}),_c('b-row',{staticClass:"mt-0"},[_c('b-col',{attrs:{"cols":"4"}},[_c('b-tabs',{staticClass:"customer-list-page__body__tabs",attrs:{"card":""}},[_c('b-tab',{attrs:{"active":""},on:{"click":function($event){return _vm.onChangeComponent('TableInprogress')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v("Đang diễn ra")])]},proxy:true}])}),_c('b-tab',{on:{"click":function($event){return _vm.onChangeComponent('TableFinished')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',[_vm._v("Đã kết thúc")])]},proxy:true}])})],1)],1),(Object.keys(_vm.filters.group).length)?_c('b-col',{staticClass:"header-comment"},[(
            _vm.isTabInprogress &&
            (_vm.isWriteAllPermission || _vm.isWriteOwnerPermission)
          )?_c('b-button',{staticClass:"btn btn-success ml-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.redirectToZalo(_vm.filters.group.linkZalo)}}},[_c('span',{staticClass:"svg-icon"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Neolex/Chatting/comment-group.svg"}})],1),_vm._v(" Nhắn tin nhóm ")]):_vm._e(),(_vm.isWriteAllPermission || _vm.isWriteOwnerPermission)?_c('b-button',{staticClass:"btn btn-success ml-2",attrs:{"type":"button"},on:{"click":_vm.onShowCommentsModal}},[_c('span',{staticClass:"svg-icon"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Neolex/Chatting/comment.svg"}})],1),_vm._v(" Nhận xét ")]):_vm._e(),(
            _vm.isTabInprogress &&
            (_vm.isWriteAllPermission || _vm.isWriteOwnerPermission)
          )?_c('b-button',{staticClass:"btn btn-success ml-2",attrs:{"type":"button"},on:{"click":_vm.onShowSurveyModal}},[_c('span',{staticClass:"svg-icon"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Neolex/Basic/plus.svg"}})],1),_vm._v(" Thêm mới khảo sát ")]):_vm._e()],1):_vm._e()],1)],1),(
      Object.keys(_vm.filters.coach).length && Object.keys(_vm.filters.group).length
    )?_c('transition',{attrs:{"name":"fade"}},[(_vm.isTabInprogress)?_c('div',{staticClass:"wrapper-cards"},[_c('InprogressCards',{on:{"search":_vm.onTabChange}})],1):_vm._e()]):_vm._e(),_c('Wrapper',[_c(_vm.currentComponent,{ref:_vm.currentComponent,tag:"component",attrs:{"filters":_vm.filters,"is-inprogress":_vm.isTabInprogress,"tab-filter":_vm.tabFilter},on:{"save":_vm.getCustomers}})],1),_c('CommentsModal',{attrs:{"groupId":_vm.filters.group && _vm.filters.group.id},on:{"reset":_vm.resetCommentModal}}),_c('SurveyFormModal',{attrs:{"groupId":_vm.filters.group && _vm.filters.group.id},on:{"reset":_vm.resetSurveyModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }